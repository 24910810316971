import axios from "axios";
import { baseUrl } from "../utils/consts.js";

const fetchLogin = async (email, password, token) => {
  try {
    let { data } = await axios.post(`${baseUrl}/auth/login`, {
      email,
      password,
      token,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

const loginWithGoogle = async (token, gift_code) => {
  try {
    let { data } = await axios.post(`${baseUrl}/auth/login/google`, {
      token,
      gift_code,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

const fetchForgetPassword = async (email) => {
  try {
    let { data } = await axios.post(`${baseUrl}/auth/forget-password`, {
      email,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

const fetchForgetPasswordConfirm = async (token, password, re_password) => {
  try {
    let { data } = await axios.put(`${baseUrl}/auth/forget-password`, {
      token,
      password,
      re_password,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export {
  fetchLogin,
  loginWithGoogle,
  fetchForgetPassword,
  fetchForgetPasswordConfirm,
};
