import { createSlice } from "@reduxjs/toolkit";

const authReducer = createSlice({
  name: "auth",
  initialState: {
    token: "",
    currentUser: {
      balance: 0,
    },
  },
  reducers: {
    SetCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
    SetToken(state, action) {
      state.token = action.payload;
    },
    RemoveToken(state) {
      state.token = "";
    },
  },
});

export const actions = authReducer.actions;
export default authReducer.reducer;
