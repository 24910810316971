import { createSlice } from "@reduxjs/toolkit";

const gameReducer = createSlice({
  name: "games",
  initialState: {
    list: [],
    selectedGame: {},
    count: 0,
  },
  reducers: {
    SetGames(state, action) {
      state.list = Object.assign([], action.payload.list);
      state.count = action.payload.count;
    },
    SetSelectedGame(state, action) {
      state.selectedGame = action.payload;
    },
  },
});

export const actions = gameReducer.actions;
export default gameReducer.reducer;
